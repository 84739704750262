const _ = require("lodash")

const menu = {
  Medicine: [
    "Medicine",
    "Cardiology",
    "Dermatology",
    "Nephrology",
    "Endocrinology",
    "Neurology",
    "Pulmonology",
    "Gastroenterology",
    "Rheumatology",
    "Haematology",
    "Psychiatry",
    "Hepatology",
    "Physical Medicine",
    "Oncology",
  ],
  Paediatrics: ["Paediatrics", "Paediatric Neurology", "Paediatric Nephrology", "Paediatric Cardiology", "Paediatric Haematology"],
  Surgery: [
    "Surgery",
    "Orthopaedics",
    "ENT",
    "Urology",
    "Paediatric Surgery",
    "Colorectal Surgery",
    "Neuro Surgery",
    "Opthalmology",
    "Anesthesiology",
    "Breast Surgery",
    "Cardiothoracic Surgery",
    "Surgical Oncology",
    "Vuscular Surgery",
    "Hepatobiliary Surgery",
    "Plastic and Burn Surgery"
  ],
  Gynaecology: "Gynaecology",
  "Dental Surgery": "Dental Surgery",
  Radiology: ["Radiology And Imaging", "Nuclear Medicine"],
}

const menuWithSynonyms = [
  ["Medicine"],
  ["Cardiology", "Heart"],
  ["Dermatology", "Skin & Venereal"],
  ["Nephrology", "Kidney"],
  ["Endocrinology", "Hormone, DM & Obesity"],
  ["Neurology", "Nerve"],
  ["Pulmonology", "Lung, Asthma"],
  ["Gastroenterology", "Stomach"],
  ["Rheumatology"],
  ["Haematology", "Blood"],
  ["Psychiatry", "Mad, Schizophrenia"],
  ["Hepatology", "Liver"],
  ["Physical Medicine"],
  ["Oncology", "Tumour, Cancer"],
  ["Paediatrics", "Child"],
  ["Surgery"],
  ["Orthopaedics", "Bone"],
  ["ENT", "Ear, Nose, Throat"],
  ["Urology", "Kidney Surgery"],
  ["Paediatric Surgery", "Child Surgery"],
  ["Colorectal Surgery"],
  ["Neuro Surgery"],
  ["Opthalmology", "Eye"],
  ["Anesthesiology"],
  ["Gynaecology", "Female, Mother, Infertility"],
  ["Dental Surgery"],
  ["Radiology And Imaging"],
  ["Nuclear Medicine"],
  [ "Breast Surgery"],
  ["Cardiothoracic Surgery", "Heart Lung Surgery"],
  ["Surgical Oncology", "Cancer Surgery"],
  ["Paediatric Neurology" , "Child Neurology"], 
  ["Paediatric Nephrology", "Child Nephrology"], 
  ["Paediatric Cardiology" , "Child Cardiology"],
  ["Paediatric Haematology", "Child Blood Medicine"],
  ["Vuscular Surgery"],
  ["Hepatobiliary Surgery", "Liver surgery"],
  ["Plastic and Burn Surgery"]
]

module.exports = {
  menu,
  menuWithSynonyms,
  flatMenu: _.flatMap(menu),
}
